<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12 sm6 md4>
                            <v-text-field
                                v-model="item.name"
                                label="Nombre"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog"
                >Cancela</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem">Guarda</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'roleDialogo',
    data() {
        return {};
    },

    computed: {
        ...mapState('role', ['item']),
        formTitle() {
            return this.index === -1 ? 'Nuevo rol' : 'Edita rol';
        }
    },

    methods: {
        ...mapActions('role', ['closeDialog', 'saveItem'])
    }
};
</script>
