<template>
    <role></role>
</template>

<script>
import role from '@/components/User/RoleCrud';

export default {
    name: 'rol',
    data() {
        return {};
    },
    components: {
        role
    }
};
</script>
