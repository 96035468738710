<template>
    <v-data-table
        :headers="headers"
        dense
        :items="items"
        sort-by="id"
        :search="search"
        class="elevation-1"
        @click:row="editItem"
    >
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Roles</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="propDialog" max-width="800px" persistent>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="warning"
                            v-on:click="editItem(null)"
                        >
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <roleDialogo></roleDialogo>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import roleDialogo from '@/components/User/RoleDialogo';

export default {
    name: 'roleCrud',
    data() {
        return {
            search: '',
            formTitle: '',
            headers: [
                { text: 'Id', align: 'left', sortable: true, value: 'id' },
                { text: 'Rol', value: 'name' },
                { text: 'Opciones', value: 'action', sortable: false }
            ]
        };
    },

    computed: {
        ...mapState('role', ['dialog', 'items']),

        propDialog: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.setDialog(value);
            }
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        ...mapActions('role', ['initialize', 'editItem', 'setDialog'])
    },
    components: {
        roleDialogo
    }
};
</script>
